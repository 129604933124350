var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass:
        "flex flex-col items-center w-full px-4 pt-8 bg-gray-800 sm:px-8",
    },
    [
      _c("div", { staticClass: "flex justify-between w-full pb-8" }, [
        _c(
          "div",
          { staticClass: "flex items-center justify-start" },
          [
            _vm.customDomain
              ? _c("custom-brand-img", {
                  attrs: {
                    type: _vm.customImgTypes.BG_DARK,
                    "img-class": "custom-brand w-48",
                  },
                })
              : _c("sun-media-logo-light", { staticClass: "w-48 flex" }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "relative flex items-center justify-start" },
          [_c("asterix-user-menu")],
          1
        ),
      ]),
      _c("div", { staticClass: "container flex justify-start w-full" }, [
        _c(
          "div",
          { staticClass: "flex items-center text-gray-400" },
          _vm._l(_vm.configTabs, function (context, index) {
            return _c(
              "div",
              {
                key: `${index}-tab`,
                staticClass:
                  "w-auto pb-2 mb-1 mr-10 text-lg font-bold whitespace-no-wrap border-b-4 border-transparent cursor-pointer md:mb-0 hover:text-white hover:pb-0 hover:border-orange-500",
                class: _vm.isActiveRoute(context)
                  ? "text-white border-b-4 border-orange-500 cursor-auto disabled"
                  : "",
                on: {
                  click: function ($event) {
                    return _vm.navigateTo(context)
                  },
                },
              },
              [_vm._v(" " + _vm._s(context.name) + " ")]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }