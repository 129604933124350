<template>
  <context-board-layout />
</template>

<script>
import ContextBoardLayout from '@/components/templates/ContextBoardLayout';

export default {
  name: 'ContextBoardIndex',
  components: {
    ContextBoardLayout,
  },
};
</script>
