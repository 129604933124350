<template>
  <header class="flex flex-col items-center w-full px-4 pt-8 bg-gray-800 sm:px-8">
    <div class="flex justify-between w-full pb-8">
      <div class="flex items-center justify-start">
        <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_DARK" img-class="custom-brand w-48" />
        <sun-media-logo-light v-else class="w-48 flex" />
      </div>
      <div class="relative flex items-center justify-start">
        <asterix-user-menu />
      </div>
    </div>
    <div class="container flex justify-start w-full">
      <div class="flex items-center text-gray-400">
        <div
          v-for="(context, index) in configTabs"
          :key="`${index}-tab`"
          class="w-auto pb-2 mb-1 mr-10 text-lg font-bold whitespace-no-wrap border-b-4 border-transparent cursor-pointer md:mb-0 hover:text-white hover:pb-0 hover:border-orange-500"
          :class="isActiveRoute(context) ? 'text-white border-b-4 border-orange-500 cursor-auto disabled' : ''"
          @click="navigateTo(context)"
        >
          {{ context.name }}
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsterixUserMenu from '@/components/molecules/shared/AsterixUserMenu';
import { USER } from '@/store/modules/auth/keys';
import { RESET_PARAMS_EXCEPT } from '@/store/modules/contextBoard/keys';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'ContextBoardHeader',
  components: {
    AsterixUserMenu,
    CustomBrandImg,
    SunMediaLogoLight: () => import('../../../../icons/SunMediaLogoLight'),
  },
  data() {
    return {
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
  computed: {
    ...mapGetters({
      user: USER,
    }),
    rolesAuth() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.CORE.id);
    },
    isAdminUser() {
      return this.rolesAuth?.role === ROLES.ADMIN.id;
    },
    configTabs() {
      return this.isAdminUser
        ? [
            {
              name: 'Workspaces',
              to: 'workspaces',
              filtersToMantain: ['type'],
            },
            {
              name: 'Users',
              to: 'users',
              filtersToMantain: ['type'],
            },
          ]
        : [
            {
              name: 'Workspaces',
              to: 'workspaces',
            },
          ];
    },
  },
  methods: {
    ...mapActions({
      resetParamsExcept: RESET_PARAMS_EXCEPT,
    }),
    getNameRoute(routeEntity) {
      return `${CONTEXT_BOARD_BASE}-${routeEntity}`;
    },
    navigateTo(context) {
      if (!this.isActiveRoute(context)) {
        this.resetParamsExcept(context.filtersToMantain);
        this.$router.push({ name: this.getNameRoute(context.to) });
      }
    },
    isActiveRoute(context) {
      return this.$route.path.includes(context.to);
    },
  },
};
</script>
<style>
.custom-brand {
  max-height: 100px;
}
</style>
