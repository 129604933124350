<template>
  <div class="min-h-screen relative flex flex-col justify-between">
    <div>
      <slot name="header">
        <context-board-header />
      </slot>

      <section class="mx-auto h-full">
        <slot name="main">
          <router-view />
        </slot>
      </section>
    </div>
    <slot name="footer">
      <asterix-footer class="context-board-footer" />
    </slot>
  </div>
</template>

<script>
import ContextBoardHeader from '@/components/molecules/modules/core/contextBoard/ContextBoardHeader';
import AsterixFooter from '@/components/molecules/shared/AsterixFooter';

export default {
  name: 'ContextBoardLayout',
  components: {
    ContextBoardHeader,
    AsterixFooter,
  },
};
</script>

<style scoped>
.context-board-footer.py-4 {
  @apply py-2;
}
</style>
