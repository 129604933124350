var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "min-h-screen relative flex flex-col justify-between" },
    [
      _c(
        "div",
        [
          _vm._t("header", function () {
            return [_c("context-board-header")]
          }),
          _c(
            "section",
            { staticClass: "mx-auto h-full" },
            [
              _vm._t("main", function () {
                return [_c("router-view")]
              }),
            ],
            2
          ),
        ],
        2
      ),
      _vm._t("footer", function () {
        return [_c("asterix-footer", { staticClass: "context-board-footer" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }